import Vue from 'vue';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { getAmplifyConfig } from 'mdfit-frontend-common/api/amplify-config';
import CustomTokenStorage from './store/modules/CustomTokenStorage.js';
import vuetify from '../../plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store/store';

Vue.config.productionTip = false;

// Redirect to tenant specific site if coming from a custom url
const host = window.location.hostname;
if (host === 'login.gripaconnect.com') {
	if (
		window.location.pathname === '/'
		|| window.location.pathname === ''
	) {
		window.location.pathname = '/org/GRIPA';
	}
}

getAmplifyConfig().then((config) => {
	if (config.Auth.Cognito.userPoolId) {
		Amplify.configure(config);
		cognitoUserPoolsTokenProvider.setKeyValueStorage(new CustomTokenStorage());
	}
	const apis = { ...config.API.REST };
	Object.keys(apis).forEach((api) => {
		apis[api] = apis[api].endpoint;
	});
	store.commit('setApiMap', apis);
});

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App),
}).$mount('#app');
