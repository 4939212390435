/**
 * This file handles accessing an adfs server for oauth2 logins
 */
import config from 'mdfit-frontend-common/api/config';

export const userLogin = (tenantInfo, redirectUrl) => {
	window.location.href = `https://${tenantInfo.cognitoDomain}` +
	`.auth.${tenantInfo.cognitoRegion}.amazoncognito.com/authorize` +
	`?idp_identifier=${tenantInfo.ssoName}&` +
	'response_type=code&' +
	`client_id=${tenantInfo.userPoolClientId}&` +
	'scope=openid+email+profile+aws.cognito.signin.user.admin+api-gateway/all&' +
	`redirect_uri=${redirectUrl}`;
};

export const getTokens = (tenantInfo) => {
	const query = window.location.href.split('?')[1];
	const params = query.split('&').map(q => q.split('='));
	const codeParam = params.find(p => p[0] === 'code');
	const code = codeParam[1];
	return fetch(`https://${tenantInfo.cognitoDomain}` +
	`.auth.${tenantInfo.cognitoRegion}.amazoncognito.com/oauth2/token`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: [
			'grant_type=authorization_code',
			`client_id=${tenantInfo.userPoolClientId}`,
			`code=${code}`,
			`redirect_uri=${config.loginUrl}/idpresponse`,
		].join('&'),
	}).then(response => response.json());
};
