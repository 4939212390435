<template>
	<v-app>
		<router-view />
		<v-footer class="pb-4" color="white">
			<v-spacer />
				<span class="caption">
					© 2013 - {{ moment().format('YYYY') }}
					<a href="https://www.mdfit.com" target="_blank" style="color:#666666"><b>Axixe LLC</b></a> |
					{{ version }} |
					<a href="https://mdfit.com/dmca" target="_blank" style="color:#666666;">Copyright &amp; Trademark</a> |
					<a href="https://mdfit.com/terms" target="_blank" style="color:#666666;">Terms of Use</a> |
					<a href="https://mdfit.com/privacy" target="_blank" style="color:#666666;">Privacy Policy</a>
				</span>
			<v-spacer />
		</v-footer>
	</v-app>
</template>

<script>
import moment from 'moment';
import 'vuetify/dist/vuetify.css';
import Login from './views/Login.vue';

export default {
	name: 'Login',
	data: () => ({
		// eslint-disable-next-line no-undef
		version: VERSION_NUMBER,
	}),
	computed: {
		loggedIn() {
			return this.$store.state.user.loggedIn;
		},
	},
	methods: {
		moment,
	},
	components: {
		Login,
	},
};
</script>
