<template>
	<v-main class="mfa-login-page">
		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md4>
					<v-card class="elevation-12">
						<v-toolbar dark color="primary">
							<v-toolbar-title>
								Enter your MFA code
							</v-toolbar-title>
							<v-spacer></v-spacer>
						</v-toolbar>
						<v-card-text>
							<v-form ref="form">
								<v-text-field
									v-model="mfaCode"
									name="mfa-code"
									label="MFA Code"
									type="text"
									autocomplete="off"
									:rules="rules.mfaCode"
									@submit.prevent="login"
									@keypress.enter.prevent="login"
								></v-text-field>
								<p v-if="error" class="red--text">{{ error }}</p>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-checkbox v-model="doMfaChange" label="Setup a different mfa token after login"></v-checkbox>
							<v-spacer></v-spacer>
							<v-btn color="primary" v-on:click="login" :loading="loading">Login</v-btn>
						</v-card-actions>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-main>
</template>

<script>
import { confirmSignIn, fetchAuthSession } from 'aws-amplify/auth';
import cookieManager from 'mdfit-frontend-common/api/cookieManager';
import config from 'mdfit-frontend-common/api/config';

export default {
	data: () => ({
		mfaCode: '',
		rules: {
			mfaCode: [
				v => !!v || 'Password is required',
			],
		},
		loading: false,
		error: null,
		doMfaChange: false,
	}),
	computed: {
		cognitoUser() {
			return this.$store.state.user.cognitoUser;
		},
		tenantSettings() {
			return this.$store.state.tenantSettings;
		},
	},
	watch: {
		cognitoUser(user) {
			if (!user) {
				this.$router.push('/');
			}
		}
	},
	mounted() {
		if (!this.cognitoUser) {
			this.$router.push('/');
		}
	},
	methods: {
		login() {
			const valid = this.$refs.form.validate();
			if (valid) {
				this.error = null;
				this.loading = true;
				confirmSignIn({
					challengeResponse: this.mfaCode,
				}).then(() => fetchAuthSession()).then((user) => {
					cookieManager.setCookie('expires_at', Date.now() +
						(user.tokens.accessToken.payload.exp * 1000));
					this.loading = false;

					if (this.doMfaChange) {
						this.setupMfa(user);
					} else {
						const storedRedirect = cookieManager.getCookie('loginRedirect');
						if (storedRedirect) {
							cookieManager.deleteCookie('loginRedirect');
							window.location.href = decodeURIComponent(storedRedirect);
						} else {
							window.location.href = this.tenantSettings.homePage || config.homePage;
						}
					}
				}).catch((error) => {
					console.error(error);
					this.loading = false;
					this.error = error.message;
				}).finally(() => {
					this.loading = false;
				});
			}
		},
		setupMfa(user) {
			this.$router.push({ path: '/setupMfa', name: 'setupMFA', params: { username: user.username } });
		}
	},
};
</script>
