<template>
	<v-container class="mfa-setup">
		<v-card width="600" style="margin: 0 auto; top: 50%; transform: translateY(-50%)"
			:style="{
				top: isMobile ? null : '50%',
				transform: isMobile ? null : 'translateY(-50%)'
			}"
		>
			<v-card-title>Multi-Factor Authentication Setup</v-card-title>
			<v-card-text>
				<v-form ref="form">
					<div>Multi-Factor Authentication is required. Please choose a method to set-up</div>
					<div v-if="method === 'totp'">
						<div>Scan this QR code with your authenticator app, and then enter the code.</div>
						<div>{{ totpCode }}</div>
						<div style="width: 300px; margin: 0 auto;" v-html="qrcode"></div>
						<v-text-field
							v-model="totpAnswer"
							outlined
							label="Authenticator Code"
							@submit.prevent="saveMfa"
							@keypress.enter.prevent="saveMfa"
						/>
						<div>
							<v-btn @click="saveMfa" :loading="loading" class="mr-2">Submit</v-btn>
							<v-btn
								v-if="!cognitoUser?.nextStep?.totpSetupDetails"
								text small @click="method = 'sms'" :disabled="loading">Setup SMS multi-factor instead</v-btn>
						</div>
					</div>
					<div v-else-if="method === 'sms'">
						<template v-if="!smsStep2">
							<div>Please enter a phone number that can receive text messages. You will receive a code via sms.</div>
							<v-text-field
								v-model="phoneNumber"
								label="Phone Number"
								outlined
								@submit.prevent="saveMfa"
								@keypress.enter.prevent="saveMfa"
								:disabled="smsStep2"
								:rules="[
									a => parsePhoneNumber(a, 'US')?.isValid() || 'Please enter a valid phone number.'
								]"
							/>
						</template>
						<template v-else>
							<div>Please enter the code sent to the phone number to continue.</div>
							<v-text-field
								v-show="smsStep2"
								v-model="phoneVerify"
								label="Verification Code"
								outlined
								@submit.prevent="saveMfa"
								@keypress.enter.prevent="saveMfa"
								:disabled="!smsStep2"
							/>
							<v-btn v-if="smsStep2" @click="smsStep2 = false" small text>Change phone number</v-btn>
						</template>
						<div class="mt-2">
							<v-btn @click="saveMfa" :loading="loading" class="mr-2">Submit</v-btn>
							<v-btn text small @click="method = 'sms'" :disabled="loading">Setup software multi-factor authentication instead</v-btn>
						</div>
					</div>
				</v-form>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import {
	setUpTOTP,
	verifyTOTPSetup,
	updateMFAPreference,
	fetchAuthSession,
	confirmSignIn,
	updateUserAttribute,
	confirmUserAttribute,
} from 'aws-amplify/auth';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';

import qr from 'qrcode-generator';
import cookieManager from 'mdfit-frontend-common/api/cookieManager';

export default {
	data: () => ({
		method: null,
		totpCode: '',
		qrcode: '',
		totpAnswer: '',
		loading: false,
		phoneNumber: '',
		phoneVerify: '',
		smsStep2: false,
	}),
	computed: {
		cognitoUser() {
			return this.$store.state.user.cognitoUser;
		},
		isMobile() {
			return this.$vuetify.breakpoint.lgAndDown;
		},
		tenantSettings() {
			return this.$store.state.tenantSettings;
		},
		attemptedUsername() {
			return this.$store.state.attemptedUsername;
		},
	},
	mounted() {
		this.method = 'totp';
	},
	watch: {
		async method() {
			if (this.method === 'totp' && !this.totpCode) {
				let uri = null;
				if (this.cognitoUser?.nextStep?.totpSetupDetails) {
					uri = this.cognitoUser.nextStep.totpSetupDetails.getSetupUri('MDfit', this.attemptedUsername || '');
					this.totpCode = this.cognitoUser.nextStep.totpSetupDetails.sharedSecret;
				} else {
					const totp = await setUpTOTP();
					uri = totp.getSetupUri('MDfit', this.attemptedUsername || '');
					this.totpCode = totp.sharedSecret;
				}
				const qrcode = qr(0, 'L');
				qrcode.addData(uri.href);
				qrcode.make();
				this.qrcode = qrcode.createSvgTag({
					cellSize: 3,
					scalable: true,
				});
			}
		},
		phoneNumber(val) {
			const next = new AsYouType('US').input(val);
			if (next !== val) {
				this.phoneNumber = next;
			}
		},
	},
	methods: {
		parsePhoneNumber,
		saveMfa() {
			if (this.method === 'totp') {
				this.loading = true;
				let promise = null;
				if (this.cognitoUser?.nextStep?.totpSetupDetails) {
					promise = confirmSignIn({
						challengeResponse: this.totpAnswer,
					});
				} else {
					promise = verifyTOTPSetup({
						code: this.totpAnswer,
					});
				}
				promise.then(() => {
					console.log('Code verified.');
					updateMFAPreference({ totp: 'PREFERRED' }).then(() => {
						console.log('Preferred mfa set to authorizer app');
					});
					console.log('Sign in confirmed');
					fetchAuthSession().then((session) => {
						cookieManager.setCookie('expires_at', Date.now()
							+ (session.tokens.accessToken.payload.exp * 1000));
						this.redirect();
					});
					this.loading = false;
				}).catch((error) => {
					console.error(error);
					this.loading = false;
					this.$store.commit('toaster/error', error);
				});
			} else if (this.method === 'sms') {
				if (!this.smsStep2) {
					const phone = parsePhoneNumber(this.phoneNumber, 'US');
					if (!phone) { return; }
					this.loading = true;
					updateUserAttribute({
						userAttribute: {
							attributeKey: 'phone_number',
							value: phone.number,
						},
					}).then((res) => {
						console.log(res);
						if (res.nextStep.updateAttributeStep === 'DONE') {
							// already verified
							return updateMFAPreference({ sms: 'PREFERRED' })
								.then(() => {
									this.redirect();
								})
						}
						this.smsStep2 = true;
					}).catch((error) => {
						console.error(error);
						this.$store.commit('toaster/error', error);
					}).finally(() => {
						this.loading = false;
					});
				} else {
					confirmUserAttribute({
						userAttributeKey: 'phone_number',
						confirmationCode: this.phoneVerify,
					}).then(() => {
						return updateMFAPreference({ sms: 'PREFERRED' });
					}).then(() => {
						this.redirect();
					}).catch((error) => {
						console.error(error);
						this.$store.commit('toaster/error', error);
					});
				}
			}
		},
		redirect() {
			const storedRedirect = cookieManager.getCookie('loginRedirect');
			if (storedRedirect) {
				cookieManager.deleteCookie('loginRedirect');
				window.location.href = decodeURIComponent(storedRedirect);
			} else {
				window.location.href = this.tenantSettings.homePage || config.homePage;
			}
		}
	},
};
</script>
