import cookieManager from 'mdfit-frontend-common/api/cookieManager';

export default class CustomTokenStorage {
	async setItem(key, value) {
		const splitKey = key.split('.').pop();
		if (splitKey === 'accessToken') {
			cookieManager.setCookie('access_token', value);
		} else if (splitKey === 'refreshToken') {
			cookieManager.setCookie('refresh_token', value);
		} else {
			cookieManager.setCookie(splitKey, value);
		}
	}

	async getItem(key) {
		const splitKey = key.split('.').pop();
		if (splitKey === 'accessToken') {
			return cookieManager.getCookie('access_token');
		} else if (splitKey === 'refreshToken') {
			return cookieManager.getCookie('refresh_token');
		} else {
			return cookieManager.getCookie(splitKey);
		}
	}

	async removeItem(key) {
		const splitKey = key.split('.').pop();
		if (splitKey === 'accessToken') {
			cookieManager.setCookie('access_token', '');
			cookieManager.deleteCookie('access_token');
		} else if (splitKey === 'refreshToken') {
			cookieManager.setCookie('refresh_token', '');
			cookieManager.deleteCookie('refresh_token');
		} else {
			cookieManager.setCookie(splitKey, '');
			cookieManager.deleteCookie(splitKey);
		}
	}

	async clear() {
	}
}