<template>
	<v-container class="mfa-setup">
		<v-card width="600" style="margin: 0 auto; top: 50%; transform: translateY(-50%)"
			:style="{
				top: isMobile ? null : '50%',
				transform: isMobile ? null : 'translateY(-50%)'
			}"
		>
			<v-card-title>Multi-Factor Authentication Setup</v-card-title>
			<v-card-text>
				<v-form ref="form">
					<div>Multi-Factor Authentication is required. Please choose a method to set-up</div>
					<v-btn text class="mb-2" @click="saveMfa('TOTP')">One time password Authenticator App</v-btn>
					<v-btn text @click="saveMfa('SMS')">Receive code via text message</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { confirmSignIn } from 'aws-amplify/auth';

import cookieManager from 'mdfit-frontend-common/api/cookieManager';

export default {
	data: () => ({
		method: null,
		totpCode: '',
		qrcode: '',
		totpAnswer: '',
		loading: false,
		phoneNumber: '',
		phoneVerify: '',
		smsStep2: false,
	}),
	computed: {
		cognitoUser() {
			return this.$store.state.user.cognitoUser;
		},
		isMobile() {
			return this.$vuetify.breakpoint.lgAndDown;
		},
		tenantSettings() {
			return this.$store.state.tenantSettings;
		},
	},
	mounted() {
		if (!this.cognitoUser) {
			this.$router.push('/');
		}
		this.method = 'totp';
	},
	methods: {
		async saveMfa(method) {
			confirmSignIn({
				challengeResponse: method,
			}).then((data) => {
				this.$store.commit('user/setChallengeName', data.ChallengeName);
				if (data.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'
					|| data.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
				) {
					this.$router.push('/mfaToken');
				}
			});
		},
		redirect() {
			const storedRedirect = cookieManager.getCookie('loginRedirect');
			if (storedRedirect) {
				cookieManager.deleteCookie('loginRedirect');
				window.location.href = decodeURIComponent(storedRedirect);
			} else {
				window.location.href = this.tenantSettings.homePage || config.homePage;
			}
		}
	},
};
</script>
