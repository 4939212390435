<template>
	<v-main class="forgot-password">
		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md4>
					<v-card class="elevation-12">
						<v-toolbar dark color="primary">
							<v-toolbar-title>
								Forgot Password
							</v-toolbar-title>
							<v-spacer></v-spacer>
						</v-toolbar>
						<v-card-text v-if="!codeSent">
							<v-form ref="usernameForm">
								<v-text-field
									v-model="username"
									label="Username"
									type="text"
									autocomplete="username email"
									:rules="[
										v => !!v || 'Username is required',
										v => !usernameError || usernameError,
									]"
									@submit.prevent="sendResetEmail"
									@keypress.enter.prevent="sendResetEmail"
								></v-text-field>
								<v-btn
									:loading="loading"
									@click="sendResetEmail"
									color="primary"
									class="mr-2"
								>Send Reset Email</v-btn>
								<v-btn @click="backToLogin()">Cancel</v-btn>
							</v-form>
						</v-card-text>
						<v-card-text v-else>
							<p>
								If you provided a valid username/email, you will receive an email with a reset code.
								Please enter it below to reset your password.
							</p>
							<v-form ref="form">
								<v-text-field
									v-model="resetCode"
									name="reset-code"
									label="Reset Code"
									type="text"
									autocomplete="off"
									:rules="[v => !!v || 'Reset code is required']"
									@submit.prevent="resetPassword"
									@keypress.enter.prevent="resetPassword"
								></v-text-field>
								<v-text-field
									v-model="password"
									name="password"
									label="Password"
									type="password"
									autocomplete="new-password"
									:rules="rules.password"
									@submit.prevent="resetPassword"
									@keypress.enter.prevent="resetPassword"
								></v-text-field>
								<v-text-field
									v-model="confirmPassword"
									name="confirm-password"
									label="Confirm Password"
									type="password"
									autocomplete="new-password"
									:rules="confirmPasswordRules"
									@submit.prevent="resetPassword"
									@keypress.enter.prevent="resetPassword"
								></v-text-field>
								<p v-if="resetError" class="red--text">{{ resetError }}</p>
								<v-btn
									color="primary"
									class="mr-2"
									v-on:click="resetPassword"
									:loading="loading"
								>Submit</v-btn>
								<v-btn @click="$router.push('/')">Cancel</v-btn>
							</v-form>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-main>
</template>

<script>
import { confirmResetPassword } from 'aws-amplify/auth';
import config from 'mdfit-frontend-common/api/config';
import { getCookie } from 'mdfit-frontend-common/api/cookieManager';

const containsNumber = /^.*[0-9]+.*$/;

export default {
	data: () => ({
		codeSent: false,
		username: '',
		resetCode: '',
		password: '',
		confirmPassword: '',
		rules: {
			password: [
				v => !!v || 'Password is required',
				v => v.length >= 8 || 'Password must be at least 8 characters',
				v => v.toLowerCase() !== v || 'Password must contain at least one uppercase character',
				v => containsNumber.test(v) || 'Password must contain at least one number',
			],
		},
		loading: false,
		usernameError: null,
	}),
	computed: {
		confirmPasswordRules() {
			return [
				v => (!v || v === this.password) || 'Passwords do not match.',
			];
		},
		apiMap() {
			return this.$store.state.apiMap;
		},
	},
	methods: {
		sendResetEmail() {
			this.usernameError = null;
			const valid = this.$refs.usernameForm.validate();
			if (!valid) {
				return;
			}
			const api = this.apiMap[config.api];
			this.loading = true;
			const tenant = getCookie('tenant');
			fetch(`${api}/user/forgotPassword/${this.username.trim()}`, {
				headers: { 'x-tenant': tenant },
			}).then(async (response) => {
				if (response.ok) {
					const data = await response.json();
					if (data.sentResetCode) {
						this.codeSent = true;
					} else if (data.sentTemporaryPassword) {
						this.backToLogin({
							sentTemporaryPassword: data.sentTemporaryPassword,
							supportContact: data.supportContact,
						});
					} else if (data.emailMissing) {
						this.backToLogin({
							emailMissing: true,
							supportContact: data.supportContact,
						});
					}
					this.loading = false;
				} else {
					const err = await response.json();
					if (response.status === 400) {
						throw new Error(err.message);
					} else if (response.status === 404) {
						// User not found
						this.codeSent = true;
						this.loading = false;
					} else {
						throw new Error('Unexpected Server Error.');
					}
				}
			}).catch((error) => {
				this.loading = false;
				console.log(error);
				this.usernameError = error.message;
			});
		},
		resetPassword() {
			const valid = this.$refs.form.validate();
			if (valid) {
				this.resetError = null;
				this.loading = true;
				confirmResetPassword({
					username: this.username.toLowerCase(),
					confirmationCode: this.resetCode,
					newPassword: this.password,
				}).then(() => {
					this.$router.push('/');
					this.loading = false;
				}).catch((error) => {
					console.error(error);
					this.resetError = error.message;
					this.loading = false;
				});
			}
		},
		backToLogin(query = {}) {
			const tenant = getCookie('tenant');
			if (tenant) {
				this.$router.push({ path: `/org/${tenant}`, query });
			} else {
				this.$router.push({ path: '/', query });
			}
		},
	},
};
</script>
