import Vue from 'vue';
import Router from 'vue-router';

import Login from './views/Login.vue';
import NewPassword from './views/NewPassword.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import MFALogin from './views/MFALogin';
import IdpResponse from './views/IdpResponse.vue';
import SelectTenant from './views/SelectTenant.vue';
import Logout from './views/Logout.vue';
import Mobile from './views/Mobile.vue';
import SetupMFA from './views/SetupMFA.vue';
import SelectMFAType from './views/SelectMFAType.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.NODE_ENV === 'production' ? '/' : '/login',
	routes: [
		{ path: '/', component: Login },
		{ path: '/org/:tenant', component: Login },
		{ path: '/newpassword', component: NewPassword },
		{ path: '/forgotpassword', component: ForgotPassword },
		{ path: '/mfaToken', name: 'mfaToken', component: MFALogin },
		{ path: '/idpresponse', component: IdpResponse },
		{ path: '/accountselect', component: SelectTenant },
		{ path: '/logout', component: Logout },
		{ path: '/mobile', component: Mobile },
		{ path: '/setupMfa', name: 'setupMFA', component: SetupMFA },
		{ path: '/selectMfa', name: 'selectMFA', component: SelectMFAType },
	],
});
