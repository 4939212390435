<template>

	<v-main>
		<v-container fluid fill-height>
			<v-layout align-center justify-center column>
				<h3>You are already Logged In</h3>
				<h3>Returning to App...</h3>
			</v-layout>
		</v-container>
	</v-main>

</template>

<script>
export default {
	data() {
		return {
			token: null,
		};
	},
	methods: {
	},
	mounted() {
		window.location = `mdfitProvider://login/mobile?${window.location.href.split('?')[1]}`;
	},

};
</script>

<style>

</style>
