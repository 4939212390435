<template>
	<v-main>
		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md4>
					<v-card class="elevation-12">
						<v-toolbar dark color="primary">
							<v-toolbar-title>
								Select Account
							</v-toolbar-title>
							<v-spacer></v-spacer>
						</v-toolbar>
						<v-card-text>
							<div class="my-3 text-xs-center" v-if="loading">
								<v-progress-circular indeterminate color="primary" />
							</div>
							<div class="red--text headline" v-else-if="error">{{ error }}</div>
							<v-layout v-else>
								<v-list>
									<v-btn
										v-for="(tenant, i) in tenants"
										:key="i"
										@click="selectTenant(tenant)"
										flat>{{ tenant }}</v-btn>
								</v-list>
							</v-layout>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-main>
</template>

<script>
import { getAllowedTenants } from 'mdfit-frontend-common/api/tenants';
import cookieManager from 'mdfit-frontend-common/api/cookieManager';
import config from 'mdfit-frontend-common/api/config';

export default {
	data() {
		return {
			tenants: null,
			loading: true,
			error: null,
		};
	},
	methods: {
		selectTenant(tenant) {
			cookieManager.setCookie('tenant', tenant);
			// If url redirect use that, else see if one is stored from logging in
			// Else use the home page
			let redirect = cookieManager.getCookie('loginRedirect');
			cookieManager.deleteCookie('loginRedirect');
			const q = window.location.href.split('?')[1];
			if (q) {
				const params = q.split('&').map(p => p.split('='));
				const queryRedirect = params.find(p => p[0] === 'redirect');
				if (queryRedirect) {
					[, redirect] = queryRedirect;
				}
			}
			if (redirect) {
				window.location.href = decodeURIComponent(redirect);
			} else {
				window.location.href = config.homePage;
			}
		},
	},
	created() {
		getAllowedTenants().then((tenants) => {
			this.tenants = tenants;
			this.loading = false;
		}).catch((error) => {
			console.log(error);
			this.loading = false;
			this.error = 'Error retrieving accounts - redirecting to login';
			window.location.href = `${config.loginUrl}?redirect=${window.location.href}`;
		});
	},
};
</script>

<style>

</style>
