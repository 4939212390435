import Vue from 'vue';
import Vuex from 'vuex';

import UserStore from './modules/UserStore';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		apiMap: {},
		tenantSettings: {},
		attemptedUsername: '',
	},
	mutations: {
		setApiMap(state, map) {
			state.apiMap = map;
		},
		setTenantSettings(state, settings) {
			state.tenantSettings = settings;
		},
		setAttemptedUsername(state, name) {
			state.attemptedUsername = name;
		},
	},
	modules: {
		user: UserStore(),
	},
});
