<template>
	<v-main>
		<v-container fluid fill-height>
			<v-layout align-center justify-center column>
				<h3>You have been Logged Out</h3>
				<h3>Returning to App...</h3>
			</v-layout>
		</v-container>
	</v-main>
</template>

<script>
export default {
	mounted() {
		if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
			window.location = `mdfitProvider://mobile/logout`;
		}
		this.$router.push('/');
	},
};
</script>
